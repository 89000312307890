import axios from "axios";
import { useEffect, useState } from "react";
import DbService from "../../../Services/LocalDataService";
import "./NewSensorForm.css";
import { Button } from "@mui/material";

// interface sensorInterface {
//   name: string;
//   number: number;
//   type: string;
//   variable: string;
//   unit: string;
//   resolution: number;
//   granularity: number;
//   variable_type: string;
//   sensor_limits: string;
//   min_value: string | null;
//   max_value: string | null;
//   fixed_value: string | null;
// }
const EditUser = (props: any) => {
  const [sensor, setSensor] = useState(props.sensor);
  const [types, setTypes] = useState<Array<any>>([]);
  const [units, setUnits] = useState<any>([]);

  const [newType, setNewType] = useState(false);
  const [newTypeName, setNewTypeName] = useState<string | null>(null);
  const [newUnit, setNewUnit] = useState(false);
  const [newUnitName, setNewUnitName] = useState<string | null>(null);
  const [newUnitAbbreviation, setNewUnitAbbreviation] = useState<string | null>(
    null
  );

  useEffect(() => {
    axios.get(`/types`).then((response) => {
      setTypes(response.data);
      const aux = response.data.find(
        (element) => element.id === sensor.type_id
      ).units;
      setUnits(aux);
    });
  }, [sensor]);

  const onChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = event.target;

    setSensor({ ...sensor, [name]: value });
  };

  const onChangeCheckbox = (event: any) => {
    const { name, checked } = event.target;
    setSensor({ ...sensor, [name]: checked });
  };

  const onChangeType = (event: any) => {
    sensor.type_id = event.target.value;

    if (event.target.value === "test") {
      setNewType(true);
      setNewUnit(true);
      sensor.unit_id = "test";

      setUnits([]);
    } else {
      setNewType(false);
      const aux = types.find((element) => element.id === event.target.value);
      setUnits(aux.units);
    }
  };

  const onChangeUnit = (event: any) => {
    sensor.unit_id = event.target.value;

    if (event.target.value === "test") {
      setNewUnit(true);
    } else {
      setNewUnit(false);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let associate = false;

    if (newType) {
      const response = await axios.post("/types", { name: newTypeName });
      associate = true;
      sensor.type_id = response.data[0].id;
    }
    if (newUnit) {
      const response = await axios.post("/units", {
        name: newUnitName,
        abbreviation: newUnitAbbreviation,
      });
      sensor.unit_id = response.data[0].id;
      associate = true;
    }
    if (associate) {
      await axios.post("/types/associate", {
        type_id: sensor.type_id,
        unit_id: sensor.unit_id,
      });
    }

    const response = await DbService.editSensor(
      props.sensor.id,
      sensor.name,
      sensor.unit_id,
      sensor.granularity,
      sensor.variable,
      sensor.type_id,
      sensor.number,
      sensor.operation_limits_min_value,
      sensor.operation_limits_max_value,
      sensor.variable_type,
      sensor.sensor_limits_min_value,
      sensor.sensor_limits_max_value,
      sensor.number_of_readings,
      sensor.reading_time,
    );
    if (response.error) {
      alert(response.error);
    } else {
      props.handleResponse();
    }
  };

  const customStyle = {
    marginRight: "5px",
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="form-div-input">
          <input
            id="name"
            name="name"
            type="text"
            value={sensor.name}
            onChange={onChange}
            placeholder="Digite o nome do sensor"
          />
          <label htmlFor="name">Tag</label>
        </div>
        <div className="form-div-input">
          <input
            id="number"
            name="number"
            type="number"
            value={sensor.number}
            onChange={onChange}
            placeholder="Digite o número do sensor"
          />
          <label htmlFor="number">Numero</label>
        </div>

        <div className="form-div-input">
          <input
            id="device_id"
            name="device_id"
            type="text"
            onChange={onChange}
            value={sensor.device_id}
            placeholder="Digite o device id"
          />
          <label htmlFor="deviceId">deviceId</label>
        </div>

        <div style={{ marginTop: "13px" }}>
          <input
            id="alert_detail"
            name="alert_detail"
            type="checkbox"
            checked={sensor.alert_detail}
            onChange={onChangeCheckbox}
          />
          <label htmlFor="alert_detail">
            Mostrar no Cenário de Recomendações
          </label>
        </div>

        <div className="form-div-select">
          <select
            id="variableType"
            name="variableType"
            onChange={onChangeType}
            required
            value={sensor.type_id}
          >
            <option value="" disabled selected hidden>
              Selecione o tipo de variavel
            </option>
            {types.map((element) => (
              <option key={element.id} value={element.id}>
                {element.name}
              </option>
            ))}
            <option value={"test"}>novo tipo de variavel</option>
          </select>
          <label htmlFor="variableType">Tipo</label>
        </div>

        {newType && (
          <div className="form-div-input">
            <input
              id="novoTipo"
              name="variable"
              type="text"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setNewTypeName(event.target.value);
              }}
              placeholder="Digite o nome do tipo"
            />
            <label htmlFor="novoTipo">novoTipo</label>
          </div>
        )}

        <div className="form-div-select">
          <select
            id="variableType"
            name="variableType"
            onChange={onChangeUnit}
            value={sensor.unit_id}
            required
          >
            <option value="" disabled selected hidden>
              Selecione a unidade da váriavel
            </option>
            {units.map((element) => (
              <option key={element.id} value={element.id}>
                {element.name}({element.abbreviation})
              </option>
            ))}
            <option value={"test"}>nova unidade</option>
          </select>
          <label htmlFor="unitNew">Unidade</label>
        </div>

        {newUnit && (
          <ul>
            <div className="form-div-input">
              <input
                id="novoTipo"
                name="variable"
                type="text"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setNewUnitName(event.target.value);
                }}
                placeholder="Digite o nome do tipo"
              />
              <label htmlFor="novoTipo">nome da unidade</label>
            </div>

            <div className="form-div-input">
              <input
                id="novoTipo"
                name="variable"
                type="text"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setNewUnitAbbreviation(event.target.value);
                }}
                placeholder="Digite o nome do tipo"
              />
              <label htmlFor="novoTipo">sigla da unidade</label>
            </div>
          </ul>
        )}

        <ul>
          <div className="form-div-input">
            <input
              id="variable"
              name="variable"
              type="text"
              value={sensor.variable}
              onChange={onChange}
              placeholder="Digite o nome da variável"
            />
            <label htmlFor="variable">Nome</label>
          </div>

          <div className="double">
            <div className="form-div-input" style={customStyle}>
              <input
                id="resolution"
                name="resolution"
                type="number"
                onChange={onChange}
                value={sensor.resolution}
                placeholder="0"
                step="1"
              />
              <label htmlFor="resolution">Casas Decimais</label>
            </div>
            <div className="form-div-input">
              <input
                id="precedence"
                name="precedence"
                type="number"
                min="0"
                onChange={onChange}
                value={sensor.precedence}
                placeholder="Digite o grau de precedência"
              />
              <label htmlFor="precedence">Precedência de Aparição</label>
            </div>
          </div>
          <div className="form-div-select">
            <select
              id="variable_type"
              name="variable_type"
              onChange={onChange}
              value={sensor.variable_type}
            >
              <option value="control">Controle</option>
              <option value="monitoring">Monitoramento</option>
              <option value="kpi">KPI</option>
            </select>
            <label htmlFor="type">Tipo de Variavel</label>
          </div>

          <div className="form-div-select">
            <select
              id="sensor_limits"
              name="sensor_limits"
              onChange={onChange}
              required
              value={sensor.sensor_limits}
            >
              <option value="min_max">Valor maximo e minimo</option>
              <option value="min">Valor minimo</option>
              <option value="max">Valor maximo</option>
              <option value="fixed">Valor Fixo</option>
              <option value="none">Nenhuma</option>
            </select>
            <label htmlFor="type">Limites do sensor</label>
          </div>
          {(sensor.sensor_limits === "min" ||
            sensor.sensor_limits === "min_max") && (
            <div className="form-div-input">
              <input
                id="sensor_limits_min_value"
                name="sensor_limits_min_value"
                type="number"
                onChange={onChange}
                value={sensor.sensor_limits_min_value}
              />
              <label htmlFor="sensor_limits_min_value">Valor minimo</label>
            </div>
          )}
          {(sensor.sensor_limits === "max" ||
            sensor.sensor_limits === "min_max") && (
            <div className="form-div-input">
              <input
                id="sensor_limits_max_value"
                name="sensor_limits_max_value"
                type="number"
                onChange={onChange}
                value={sensor.sensor_limits_max_value}
              />
              <label htmlFor="sensor_limits_max_value">Valor maximo</label>
            </div>
          )}
          {sensor.sensor_limits === "fixed" && (
            <div className="form-div-input">
              <input
                id="sensor_limits_fixed_value"
                name="sensor_limits_fixed_value"
                type="number"
                onChange={onChange}
                value={sensor.sensor_limits_fixed_value}
              />
              <label htmlFor="sensor_limits_fixed_value">Valor fixo</label>
            </div>
          )}

          <div className="form-div-select">
            <select
              id="operation_limits"
              name="operation_limits"
              onChange={onChange}
              required
              value={sensor.operation_limits}
            >
              <option value="min_max">Valor maximo e minimo</option>
              <option value="min">Valor minimo</option>
              <option value="max">Valor maximo</option>
              <option value="fixed">Valor Fixo</option>
              <option value="none">Nenhuma</option>
            </select>
            <label htmlFor="type">Limites do processo</label>
          </div>
          {(sensor.operation_limits === "min" ||
            sensor.operation_limits === "min_max") && (
            <div className="form-div-input">
              <input
                id="operation_limits_min_value"
                name="operation_limits_min_value"
                type="number"
                onChange={onChange}
                value={sensor.operation_limits_min_value}
              />
              <label htmlFor="operation_limits_min_value">Valor minimo</label>
            </div>
          )}
          {(sensor.operation_limits === "max" ||
            sensor.operation_limits === "min_max") && (
            <div className="form-div-input">
              <input
                id="operation_limits_max_value"
                name="operation_limits_max_value"
                type="number"
                onChange={onChange}
                value={sensor.operation_limits_max_value}
              />
              <label htmlFor="operation_limits_max_value">Valor maximo</label>
            </div>
          )}
          {sensor.operation_limits === "fixed" && (
            <div className="form-div-input">
              <input
                id="operation_limits_fixed_value"
                name="operation_limits_fixed_value"
                type="number"
                onChange={onChange}
                value={sensor.operation_limits_fixed_value}
              />
              <label htmlFor="operation_limits_fixed_value">Valor fixo</label>
            </div>
          )}
        </ul>
        <div className="buttons">
        <Button
          onClick={props.cancel}
          style={{
            backgroundColor: "#FFF",
            color: "#124251",
            marginRight: "10px",
          }}
          variant="contained"
        >
          Cancelar
        </Button>
          <Button
            className="delete"
            onClick={() => {
              
              props.onClickDelete();
            }}
            variant="contained"
          >
            deletar
          </Button>
          <Button className="submmit" variant="contained">Salvar Sensor</Button>
        </div>
      </form>
    </div>
  );
};
export default EditUser;
