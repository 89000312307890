// import { CatchingPokemon } from "@mui/icons-material";
import axios from "axios";
import { Moment } from "moment";

const LocalDataService = {
// remove o relacionamento entre o usuário e a empresa  
  removeUserRelation: async (user_id, company_id) => {
    // console.log(user_id, company_id);
    try {
      const response = await axios.put(`/company/${company_id}/user/${user_id}`);
      return response.data;
    } catch (error) {
      console.error("There was an error removing the user relation!", error);
      throw error;
    }
  },
  // Retorna os grupos
  get_group: async function (id: string) {
    const axiosResponse = await axios.get(`/groups/${id}`);
    return axiosResponse.data;
  },

   // Adicionando o método de criação de grupo
   createGroup: async (group: any) => {
    const axiosResponse = await axios.post(`/groups/`, group);
    return axiosResponse.data;
  },

  // Adicionando o método de deleção de grupo
  deleteGroup: async (id : string) => {
    const axiosResponse = await axios.delete(`/groups/${id}`);
    return axiosResponse.data;
  },

  // edita o grupo
  editGroup: async (groupUpdate: any) => {
    const axiosResponse = await axios.put(`/groups/${groupUpdate.id}`,groupUpdate );
    return axiosResponse.data;
  },

  // Retorna as empresas
  get_companies: async function ( ) {
    const axiosResponse = await axios.get(`/companies/`);
    return axiosResponse.data;
  },

  // Retorna uma empresa por id
  getCompany: async function (id: string) {
    const axiosResponse = await axios.get(`/companies/${id}`);
    return axiosResponse.data;
  },

  // cria uma nova empresa 
  createCompany: async (company: any) => {
    const axiosResponse = await axios.post(`/companies/`, company);
    return axiosResponse.data;
  },

  // deleta uma empresa 
  deleteCompany: async (id : string) => {
    const axiosResponse = await axios.delete(`/companies/${id}`);
    return axiosResponse.data;
  },

  // edita uma empresa 
  editCompany: async (company: any) => {
    const axiosResponse = await axios.put(`/companies/${company.id}`, company);
    return axiosResponse.data;
  },



  getAlertsFromDB: async function (id: number, dates: any) {
    if (dates) {
      const axiosResponse = await axios.get(
        `/alerts?digital_twin_id=${id}&gt=${dates[0].toISOString()}&lt=${dates[1].toISOString()}`
      );
      //Esse reverse() é pra retornar os dados do mais recente pro mais antigo. O padrão é o contrário.
      return axiosResponse.data.reverse();
    }
  },
  getAlertFromDBById: async function (id: number) {
    const axiosResponse = await axios.get(`/alerts/${id}`);
    // console.log(axiosResponse.data);
    return axiosResponse.data;
  },
  getAlertForToday: async function (id: number) {
    const axiosResponse = await axios.get(`/alerts/today/${id}`);
    return axiosResponse.data;
  },
 
  get_groups: async function () {
    const axiosResponse = await axios.get(`/groups/`);
    return axiosResponse.data;
  },

  get_users_from_company: async function (company_id: string) {
    const axiosResponse = await axios.get(`/companies/users/${company_id}`);
    // console.log(axiosResponse.data.users);
    return axiosResponse.data?.users;
  },
  get_users_from_group: async function (id: string) {
    const axiosResponse = await axios.get(`/users/`)
    const users = axiosResponse.data
   

    return users.filter(user => 
      user.companies.some(company => company.group_id.toString() === id)
    );
  },
  get_sensors_from_company: async function (company_id: string) {
    const axiosResponse = await axios.get(`/companies/${company_id}/sensors`);
    // // console.log(axiosResponse.data.users);
    return axiosResponse.data;
  },
  get_digitalTwins: async function (company_id: string) {
    const axiosResponse = await axios.get(`/digitalTwin/company/${company_id}`);
    return axiosResponse.data;
  },

  get_digitalTwinsbyGroup: async function (id: string) {
    const axiosResponse = await axios.get(`/digitalTwin/group/${id}`);
    return axiosResponse.data;
  },

  get_digitalTwin: async function (dt_id: any) {
    // console.log("00000000000000");
    const axiosResponse = await axios.get(`/digitalTwin/${dt_id}`);
    // // console.log(axiosResponse.data);
    return axiosResponse.data;
  },

  get_graphs: async function (id: any) {
    // console.log(id.digitalTwins.map((dt: any) => dt.id));
    
    const axiosResponse = await axios.get(`/graphs/`)
    // console.log(axiosResponse.data);
    // console.log( axiosResponse.data.filter(
    //   (graph: any) => id.digitalTwins.map((dt: any) => dt.id).includes( graph.digital_twin_id )
    // ))

    return axiosResponse.data.filter(
      (graph: any) => id.digitalTwins.map((dt: any) => dt.id).includes( graph.digital_twin_id )
    );
  },
  store_group: async (name: string, logo?: any) => {
    const axiosResponse = await axios.post(`/groups`, {
      name,
      logo,
    });
    return axiosResponse.data;
  },

  store_country: async function (
    name: string,
    continent: string,
    date: number
  ) {
    const axiosResponse = await axios.post("/countries", {
      name,
      acronym: name.substring(0, 3).toUpperCase(),
      date,
      continent,
    });
    return axiosResponse;
  },
  store_state: async function (name: string, date: number, country_id: number) {
    const axiosResponse = await axios.post(`/states/${country_id}`, {
      name,
      acronym: name.substring(0, 2).toUpperCase(),
      date,
    });
    return axiosResponse;
  },
  store_city: async function (name: string, date: number, state_id: number) {
    const axiosResponse = await axios.post(`/cities/${state_id}`, {
      name,
      date,
    });
    return axiosResponse;
  },
  store_company: async function (
    name: string,
    group_id: number,
    city_id: number,
    cnpj: string,
    address: string,
    number: string,
    complement: string,
    neighborhood: string,
    zipcode: string,
    date: number
  ) {
    try {
      const axiosResponse = await axios.post(
        `/companies/${group_id}/${city_id}`,
        {
          name,
          cnpj,
          address,
          number,
          complement,
          neighborhood,
          zipcode,
          date,
        }
      );
      return axiosResponse;
    } catch (e: any) {
      return e.response.data;
    }
  },
  update_company: async function (
    id: number,
    name: string,
    cnpj: string,
    address: string,
    number: string,
    complement: string,
    neighborhood: string,
    zipcode: string,
    country: string,
    state: string,
    city: string,
    email: string,
    date: number
  ) {
    try {
      const axiosResponse = await axios.put(`/companies/${id}`, {
        name,
        cnpj,
        address,
        number,
        complement,
        neighborhood,
        zipcode,
        country,
        state,
        city,
        email,
        date,
      });
      return axiosResponse;
    } catch (e: any) {
      return e.response.data;
    }
  },
  store_dt: async function (
    newTwinName: string,
    newTwinIn: string, // Update this to accept a string
    newTwinOut: string, // Update this to accept a string
    companieTwin: number,
    ativeValues: number[],
    timestamp=Date.now(),
  ) {
    try {
      const axiosResponse = await axios.post(`/digitalTwin/${companieTwin}/`, {
        newTwinName,
        activeChips: [ativeValues],
        input_utility: newTwinIn,
        output_utility: newTwinOut,
        date: timestamp,
      });
      return axiosResponse;
    } catch (e: any) {
      return e.response.data;
    }
  },


  edit_dt: async function (
    id: number,
    name: string,
    input_utility: string,
    output_utility: string,
    company_id: number | null,
    ative: string | null,
    parent_id: number | null
  ) {
    try {
      const axiosResponse = await axios.put(`/digitalTwin/${id}/`, {
        name,
        input_utility,
        output_utility,
        company_id,
        ative,
        parent_id,
      });
      return axiosResponse;
    } catch (e: any) {
      return e.response.data;
    }
  },
  store_user: async function (
    name: string,
    email: string,
    permition_level: string,
    company_id: number,
    telefone: string,
  ) {
    try {
      const axiosResponse = await axios.post(`/auth/createNewCompanyUser`, {
        name,
        email,
        permition_level,
        company_id,
        telefone,
      });
      return axiosResponse;
    } catch (e: any) {
      return e.response.data;
    }
  },
  edit_user: async function (
    id: number,
    name: string,
    email: string,
    permition_level: string,
    number: string,
    company_id: number
  ) {
    try {
      const axiosResponse = await axios.put(`/users/${id}`, {
        name,
        email,
        permition_level,
        number,
        company_id,
      });
      return axiosResponse;
    } catch (e: any) {
      return e.response.data;
    }
  },
  store_sensor: async function (
    dt_id?: number,
    name?: string,
    unit?: string | number,
    granularity?: number,
    variable?: string,
    sensor_type?: string | number,
    number?: number,
    operation_limits_min_value?: number | null,
    operation_limits_max_value?: number | null,
    variable_type?: string,
    sensor_limits_min_value?: number | null,
    sensor_limits_max_value?: number | null,
    number_of_readings?: number | null,
    reading_time?: number | null,
  ) {
    console.log("store_sensor");
    console.log(dt_id);

    try {
      const axiosResponse = await axios.post(`/sensors/${dt_id}/`, {
        name,
        unit,
        granularity,
        variable,
        sensor_type,
        number,
        operation_limits_min_value,
        operation_limits_max_value,
        variable_type,
        sensor_limits_min_value,
        sensor_limits_max_value,
        number_of_readings,
        reading_time,
      });
      // console.log(axiosResponse.status);
      // console.log(axiosResponse);
      return axiosResponse;
    } catch (e: any) {
      return e.response.data;
    }
  },
  store_graph: async function (
    dt_id: number,
    name: string,
    position: string,
    time1: string,
    time2: number,

    //
    x_axis_sensor_id?: number | null,
    y_axis_1?: Array<number> | null,
    y_axis_2?: Array<number> | null,
    z_axis_sensor_id?: number | null,
    tooltip?: Array<number> | null,
    alertsAlarms?: Array<number> | null,
    areas?: Array<number> | null,

    mainSensorSpeedometer?: number | null,
    secondarySensorsSpeedometer?: Array<number> | null,
    speedometerMin?: number | null,
    speedometerMax?: number | null,
    speedometerBreak1?: number | null,
    speedometerBreak2?: number | null,
    //pie
    pieSensors?: Array<number> | null,
    //bar
    xSensorBar?: number | null,
    ySensors1Bar?: Array<number> | null,

    default_visualization?: string | null,

    line_visualization?: boolean | null,
    pie_visualization?: boolean | null,
    bar_visualization?: boolean | null,
    speedometer_visualization?: boolean | null
  ) {
    try {
      //  console.log("store_graph");
      const axiosResponse = await axios.post(`/graphs/${dt_id}/`, {
        name,
        position,
        time1,
        time2,

        //line
        x_axis_sensor_id,
        y_axis_1,
        y_axis_2,
        z_axis_sensor_id,
        tooltip,
        alertsAlarms,
        areas,

        //speed
        mainSensorSpeedometer,
        secondarySensorsSpeedometer,
        speedometerMax,
        speedometerMin,
        speedometerBreak1,
        speedometerBreak2,
        //pie
        pieSensors,
        //bar
        xSensorBar,
        ySensors1Bar,
        //
        default_visualization,

        line_visualization,
        pie_visualization,
        bar_visualization,
        speedometer_visualization,
      });
      return axiosResponse;
    } catch (e: any) {
      // console.log(e.response.data);
      return e.response.data;
    }
  },
  deleteGraph: async (id) => {
    const axiosResponse = await axios.delete(`/graphs/${id}/`);
    return axiosResponse.data;
  },
  deleteSensor: async (sensor_id) => {
    const axiosResponse = await axios.delete(`/sensors/${sensor_id}/`);
    return axiosResponse.data;
  },
  deleteUser: async (id) => {
    const axiosResponse = await axios.delete(`/users/${id}/`);
    return axiosResponse.data;
  },

  deleteDigitalTwin: async (id) => {
    const axiosResponse = await axios.delete(`/digitalTwin/${id}`);
    return axiosResponse.data;
  },

  editSensor: async (
    id: any,
    name: any,
    unit: any,
    granularity: any,
    variable: any,
    sensor_type: any,
    number: any,
    operations_limits_min_value: any,
    operations_limits_max_value: any,
    variable_type: any,
    sensor_limits_min_value: any,
    sensor_limits_max_value: any,
    number_of_readings: any,
    reading_time: any,
  ) => {
    try {
      const axiosResponse = await axios.put(`/sensors/${id}/`, {
        name,
        unit,
        granularity,
        variable,
        sensor_type,
        number,
        operations_limits_min_value,
        operations_limits_max_value,
        variable_type,
        sensor_limits_min_value,
        sensor_limits_max_value,
        number_of_readings,
        reading_time,
      });
      return axiosResponse.data;
    } catch (e: any) {
      return e.response.data;
    }
  },

  editChart: async (chart: {
    id: number;
    name: string;
    time_range: string;
    time: number;
    position_metadata: string;
    default_visualization: string;
    line_visualization: boolean;
    pie_visualization: boolean;
    bar_visualization: boolean;
    speedometer_visualization: boolean;
  }) => {
    try {
      const axiosResponse = await axios.put(`/graphs/${chart.id}/`, {
        name: chart.name,
        time_range: chart.time_range,
        time: chart.time,
        position_metadata: chart.position_metadata,
        default_visualization: chart.default_visualization,
        line_visualization: chart.line_visualization,
        pie_visualization: chart.pie_visualization,
        bar_visualization: chart.bar_visualization,
        speedometer_visualization: chart.speedometer_visualization,
      });
      return axiosResponse;
    } catch (e: any) {
      return e.response.data;
    }
  },

  editGraphSensors: async (
    id: number,
    name: string,
    time1: string,
    time2: number,
    position: string,

    //
    x_axis_sensor_id?: number | null,
    y_axis_1?: Array<number> | null,
    y_axis_2?: Array<number> | null,
    z_axis_sensor_id?: number | null,
    default_visualization?: string | null,
    line_visualization?: boolean | null,
    speedometer_visualization?: boolean | null,
    pie_visualization?: boolean | null,
    bar_visualization?: boolean | null,
    tooltip?: Array<number> | null,
    alertsAlarms?: Array<number> | null,
    areas?: Array<number> | null,

    mainSensorSpeedometer?: number | null,
    secondarySensorsSpeedometer?: Array<number> | null,
    speedometerMin?: number | null,
    speedometerMax?: number | null,
    speedometerBreak1?: number | null,
    speedometerBreak2?: number | null,
    //pie
    pieSensors?: Array<number> | null,
    //bar
    xSensorBar?: number | null,
    ySensors1Bar?: Array<number> | null,

  ) => {
    try {
      const axiosResponse = await axios.put(`/graphs/${id}/`, {
        name,
        position,
        time1,
        time2,

        //line
        x_axis_sensor_id,
        y_axis_1,
        y_axis_2,
        z_axis_sensor_id,
        tooltip,
        alertsAlarms,
        areas,

        //speed
        mainSensorSpeedometer,
        secondarySensorsSpeedometer,
        speedometerMax,
        speedometerMin,
        speedometerBreak1,
        speedometerBreak2,
        //pie
        pieSensors,
        //bar
        xSensorBar,
        ySensors1Bar,
        //
        default_visualization,

        line_visualization,
        pie_visualization,
        bar_visualization,
        speedometer_visualization,
      });
      return axiosResponse;
    } catch (e: any) {
      return e.response.data;
    }
  },
  getAnalyticsTest: async (
    sensor: number,
    startDate: Moment,
    endDate: Moment
  ) => {
    try {
      const axiosResponse = await axios.get(
        `/analytics/${sensor}?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
      );
      return axiosResponse.data;
    } catch (e) {}
  },
  getAnalyticsKpiBars: async (
    sensor: number,
    startDate: Moment,
    endDate: Moment
  ) => {
    try {
      const axiosResponse = await axios.get(
        `/analytics/${sensor}?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
      );
      return axiosResponse.data;
    } catch (e) {
      // console.log(e);
    }
  },

  alarmsInfoPeriod: async (
    sensor: number,
    startDate: Moment,
    endDate: Moment
  ) => {
    const axiosResponse = await axios.get(
      `/analytics/time/${sensor}?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
    );
    return axiosResponse.data;
  },
};

export default LocalDataService;
