import React, { useEffect, useState } from 'react';
import { Box, FormControl, InputAdornment, OutlinedInput, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, useTheme, Typography } from '@mui/material';
import DbService from "../../Services/LocalDataService";
import GroupCard from "../../Components/Cards/GroupCard/GroupCard";
// import AddGroupCard from "../../Components/Cards/AddGroupCard/AddGroupCard";
import { ReactComponent as Icon } from "../../Assets/Icons/magnifier.svg";
import IosShareIcon from '@mui/icons-material/IosShare';
import { Add } from '@mui/icons-material';


interface IGroup {
  id: number;
  name: string;
  logo: string;
  inclusion_date: string;
  createdAt: string;
  updatedAt: string;
}

const SetupView = (props: any) => {
  const theme = useTheme();
  const [listedGroups, setListedGroups] = useState<IGroup[]>([]);
  const [open, setOpen] = useState(false);
  const [newGroupName, setNewGroupName] = useState('');
  const [newGroupLogo, setNewGroupLogo] = useState<any>("");
  const [newGroupCNPJ, setNewGroupCNPJ] = useState<any>("");
  const [newGroupPontoFocal, setNewGroupPontoFocal] = useState<any>("");
  const [newGroupTelefone, setNewGroupTelefone] = useState<any>("");
  const [newGroupEmail, setNewGroupEmail ] = useState<any>("");
  const [newGroupCep, setNewGroupCep ] = useState<any>("");
  const [newGroupPais, setNewGroupPais ] = useState<any>("");
  const [newGroupEstado, setNewGroupEstado ] = useState<any>("");
  const [newGroupCidade, setNewGroupCidade  ] = useState<any>("");
  const [newGroupBairro, setNewGroupBairro ] = useState<any>("");
  const [newGroupLogradouro,  setNewGroupLogradouro ] = useState<any>("");
  const [newGroupDB,  setNewGroupDB ] = useState<any>("");
  const [newGroupCollection,  setNewGroupCollection ] = useState<any>("");




  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const filteredSensors = listedGroups.filter((sensor) => {
      if (sensor.name.toLowerCase().includes(value.toLowerCase())) {
        return sensor;
      } else {
        return null;
      }
    });
    setListedGroups(filteredSensors);
  };

  useEffect(() => {
    // carregando os grupos
    DbService.get_groups().then((data) => {
      // console.log(data);
      setListedGroups(data);
    });
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    // lógica para fechar o modal
    setNewGroupName('');
    setNewGroupLogo('');
    setNewGroupCNPJ('');
    setNewGroupPontoFocal('');
    setNewGroupTelefone('');
    setNewGroupEmail('');
    setNewGroupCep('');
    setNewGroupPais('');
    setNewGroupEstado('');
    setNewGroupCidade('');
    setNewGroupBairro('');
    setNewGroupLogradouro('');
    setNewGroupDB('');
    setNewGroupCollection('');

    setOpen(false);
  };

  const handleAddGroup =async () => {
    // lógica para adicionar o grupo
    const group = {
      
      name: newGroupName,
      logo: newGroupLogo,
      cnpj: newGroupCNPJ,
      pontoFocal: newGroupPontoFocal,
      telefone: newGroupTelefone,
      email: newGroupEmail,
      cep: newGroupCep,
      pais: newGroupPais,
      estado: newGroupEstado,
      cidade: newGroupCidade,
      bairro: newGroupBairro,
      logradouro: newGroupLogradouro,
      db_name: newGroupDB,
      collection_name: newGroupCollection,
      
    };
    const groupCreated = await DbService.createGroup(group);
    setListedGroups([...listedGroups, groupCreated]);
    handleClose();
  };

  const handleLogoChange = async(e) => {
    const file = e.target.files[0];
    file && setNewGroupLogo(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setNewGroupLogo(reader.result as string);
    };
    file && reader.readAsDataURL(file);
  }

  const deleteGroup = async (id) => {
    await DbService.deleteGroup(id);
    setListedGroups(listedGroups.filter((g) => g.id !== id))
  }

  return (
    <Box sx={{ height: "calc(100vh - 80px)", width: "calc(100vw - 70px)", marginTop: "10px" }}>
      <FormControl id="sensorsSearch" variant="outlined" style={{ width: "100%",display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
        <OutlinedInput
          id="outlined-adornment-weight"
          placeholder="Pesquisar grupos"
          aria-label="weight"
          type="text"
          size="small"
          sx={{ height: "50px", margin: "5px" }}
          startAdornment={
            <InputAdornment position="start">
              <Icon />
            </InputAdornment>
          }
          aria-describedby="outlined-weight-helper-text"
          inputProps={{
            "aria-label": "weight",
          }}
          onChange={handleSearch}
        />
      <Button sx={{
                  
                  color: "#124251",
                  
                  border: "1px solid #124251",
                  "&:hover": {
                    backgroundColor: "#124251",
                    color: "white",
                    border: "1px solid #124251",
                  },
                }}
                size="small"
                startIcon={<Add />} onClick={handleClickOpen}>Adicionar Organização</Button>
      </FormControl>

      <Box sx={{ display: "flex",gap: "10px", flexWrap: "wrap", margin: "5px" }}>
        {listedGroups.map((group) => (
          
            <GroupCard
              key={group.id}
              group={group}
              onDelete={() => deleteGroup(group.id)}
            />
          
        ))}
        {/* <AddGroupCard onClick={handleClickOpen} /> */}
      </Box>

      <Dialog open={open} onClose={handleClose} sx={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }} fullWidth={true} maxWidth={"xl"}>

        <DialogTitle sx={{ textAlign: "start", color: "inherit" }}>Nova Organização</DialogTitle>

        <DialogContent sx={{ display: "flex", justifyContent: "center", alignItems: "start" ,gap: "10px",width: "100%"}}>

          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", width: "100%" }}><Typography sx={{ textAlign: "start", color: "inherit",fontSize: "18px", fontWeight: "500" }}>Dados Gerais</Typography>
          
<input
  type="file"
  style={{ display:"none"}}
  onChange={handleLogoChange}
  accept="image/png, image/jpeg"
  id="logo-input"
/>
          <Box sx={{ display: "flex", flexDirection: "row", gap: "10px", width: "100%" ,alignItems: "start", justifyContent: "start" }}>
          <label htmlFor="logo-input" style={{ width: "140px", height: "80px",border: "1px solid #124251", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "4px", cursor: "pointer" }}>{newGroupLogo !== "" ? <img src={newGroupLogo} alt="Logo do Grupo" style={{ width: "140px", height: "78px", backgroundColor: "#124251", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "4px", objectFit: "cover" }} /> : <Typography sx={{ textAlign: "center", color: "inherit",fontSize: "18px", fontWeight: "500" }}>Logo</Typography>}</label>

<p style={{ textAlign: "start", margin: "0",padding: "0",fontSize: "11px",color: "inherit"}}>
  Clique para fazer upload do arquivo. <br/>
  Formato: .png ou .jpeg <br/>
  Tamanho máximo: 2 mb <br/><IosShareIcon/> </p>
</Box>

{/* {newGroupLogo !== "" ? <img src={newGroupLogo} alt="Logo do Grupo" style={{ width: "140px", height: "78px", backgroundColor: "#124251", display: "flex", justifyContent: "start", alignItems: "start", borderRadius: "4px", objectFit: "cover" }} /> : null} */}

<TextField
  

  label="Nome do Grupo"
  type="text"
  sx={{ width: "100%",marginTop: "10px", '& .MuiInputLabel-root': { color: theme.palette.mode === "dark" ? "white" : "black" }, '& .MuiInputBase-input': { color: theme.palette.mode === "dark" ? "white" : "black'" } }}
  value={newGroupName}
  onChange={(e) => setNewGroupName(e.target.value)}
/>

 <TextField
  
  
  label="CNPJ"
  type="text"
  sx={{ width: "100%",marginTop: "10px", '& .MuiInputLabel-root': { color: theme.palette.mode === "dark" ? "white" : "black" }, '& .MuiInputBase-input': { color: theme.palette.mode === "dark" ? "white" : "black'" } }}
  value={newGroupCNPJ}
  onChange={(e) => setNewGroupCNPJ(e.target.value)}
/>

 <TextField
  
  
  label="Ponto Focal"
  type="text"
  sx={{ width: "100%",marginTop: "10px", '& .MuiInputLabel-root': { color: theme.palette.mode === "dark" ? "white" : "black" }, '& .MuiInputBase-input': { color: theme.palette.mode === "dark" ? "white" : "black'" } }}
  value={newGroupPontoFocal}
  onChange={(e) => setNewGroupPontoFocal(e.target.value)}
/>

 <TextField
  
  
  label="Telefone"
  type="text"
  sx={{ width: "100%",marginTop: "10px", '& .MuiInputLabel-root': { color: theme.palette.mode === "dark" ? "white" : "black" }, '& .MuiInputBase-input': { color: theme.palette.mode === "dark" ? "white" : "black'" } }}
  value={newGroupTelefone}
  onChange={(e) => setNewGroupTelefone(e.target.value)}
/>

 <TextField
  
  
  label="E-mail"
  type="text"
  sx={{ width: "100%",marginTop: "10px", '& .MuiInputLabel-root': { color: theme.palette.mode === "dark" ? "white" : "black" }, '& .MuiInputBase-input': { color: theme.palette.mode === "dark" ? "white" : "black'" } }}
  value={newGroupEmail}
  onChange={(e) => setNewGroupEmail(e.target.value)}
/>

<TextField
  
  
  label="DB"
  type="text"
  sx={{ width: "100%",marginTop: "10px", '& .MuiInputLabel-root': { color: theme.palette.mode === "dark" ? "white" : "black" }, '& .MuiInputBase-input': { color: theme.palette.mode === "dark" ? "white" : "black'" } }}
  value={newGroupDB}
  onChange={(e) => setNewGroupDB(e.target.value)}
/>

<TextField
  
  
  label="Coleção"
  type="text"
  sx={{ width: "100%",marginTop: "10px", '& .MuiInputLabel-root': { color: theme.palette.mode === "dark" ? "white" : "black" }, '& .MuiInputBase-input': { color: theme.palette.mode === "dark" ? "white" : "black'" } }}
  value={newGroupCollection}
  onChange={(e) => setNewGroupCollection(e.target.value)}
/>

</Box>


          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" ,width: "100%"}}> <Typography sx={{ textAlign: "start", color: "inherit",fontSize: "18px", fontWeight: "500" }}>Localização</Typography>
           <TextField
            
            
            label="CEP"
            type="text"
            sx={{ width: "100%",marginTop: "24px", '& .MuiInputLabel-root': { color: theme.palette.mode === "dark" ? "white" : "black" }, '& .MuiInputBase-input': { color: theme.palette.mode === "dark" ? "white" : "black'" } }}
            value={newGroupCep}
            onChange={(e) => setNewGroupCep(e.target.value)}
          />

           <TextField
            
            
            label="País"
            type="text"
            sx={{ width: "100%",marginTop: "10px", '& .MuiInputLabel-root': { color: theme.palette.mode === "dark" ? "white" : "black" }, '& .MuiInputBase-input': { color: theme.palette.mode === "dark" ? "white" : "black'" } }}
            value={newGroupPais}
            onChange={(e) => setNewGroupPais(e.target.value)}
          />

          <TextField
            
            
            label="Estado"
            type="text"
            sx={{ width: "100%",marginTop: "10px", '& .MuiInputLabel-root': { color: theme.palette.mode === "dark" ? "white" : "black" }, '& .MuiInputBase-input': { color: theme.palette.mode === "dark" ? "white" : "black'" } }}
            value={newGroupEstado}
            onChange={(e) => setNewGroupEstado(e.target.value)}
          />


          <TextField
            
            
            label="Cidade"
            type="text"
            sx={{ width: "100%",marginTop: "10px", '& .MuiInputLabel-root': { color: theme.palette.mode === "dark" ? "white" : "black" }, '& .MuiInputBase-input': { color: theme.palette.mode === "dark" ? "white" : "black'" } }}
            value={newGroupCidade}
            onChange={(e) => setNewGroupCidade(e.target.value)}
          />

          <TextField
            
            
            label="Bairro"
            type="text"
            sx={{ width: "100%",marginTop: "10px", '& .MuiInputLabel-root': { color: theme.palette.mode === "dark" ? "white" : "black" }, '& .MuiInputBase-input': { color: theme.palette.mode === "dark" ? "white" : "black'" } }}
            value={newGroupBairro}
            onChange={(e) => setNewGroupBairro(e.target.value)}
          />

          <TextField
            
            
            label="Logradouro"
            type="text"
            sx={{ width: "100%",marginTop: "10px", '& .MuiInputLabel-root': { color: theme.palette.mode === "dark" ? "white" : "black" }, '& .MuiInputBase-input': { color: theme.palette.mode === "dark" ? "white" : "black'" } }}
            value={newGroupLogradouro}
            onChange={(e) => setNewGroupLogradouro(e.target.value)}
          />

          </Box>

          


         


          
        </DialogContent>
        <DialogActions sx={{ justifyContent: "end" }}>
          <Button sx={{  color: "inherit" ,border : "1px solid #124251", "&:hover": { opacity: "0.8" } }} onClick={handleClose}>Cancelar</Button>
          <Button sx={{  color: "inherit" ,border : "1px solid #124251", "&:hover": { backgroundColor: "#124251", opacity: "0.8",color: "white" } }} onClick={handleAddGroup} disabled={newGroupName === ""}>Salvar</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SetupView;
