import { MutableRefObject } from "react";

export const syncChartsTooltip = (
  arrayOfCharts: MutableRefObject<any>[],
  eventToSync: string
) => {
  arrayOfCharts.forEach((chart) => {
    chart.current
      ?.getEchartsInstance()
      .getZr()
      .on(eventToSync, (params: any) => {
        const event = params.event;
        const { offsetX, offsetY } = event;

        // Calculate percentage offsets
        const chartWidth = chart.current?.getEchartsInstance().getWidth();
        const chartHeight = chart.current?.getEchartsInstance().getHeight();
        // console.log("chart width: ", chartWidth);
        if (chartWidth && chartHeight) {
          const offsetXPercent = (offsetX / chartWidth) * 100;
          const offsetYPercent = (offsetY / chartHeight) * 100;

          // Sync the tooltip across all charts using the percentage offsets
          arrayOfCharts.forEach((targetChart) => {
            const targetWidth = targetChart.current
              ?.getEchartsInstance()
              .getWidth();
            const targetHeight = targetChart.current
              ?.getEchartsInstance()
              .getHeight();

            if (targetWidth && targetHeight) {
              const targetX = (offsetXPercent / 100) * targetWidth;
              const targetY = (offsetYPercent / 100) * targetHeight;

              targetChart.current?.getEchartsInstance().dispatchAction({
                type: "showTip",
                x: targetX,
                y: targetY,
              });
            }
          });
        }
      });
  });
};

export const syncChartsZoom = (
  arrayOfCharts: MutableRefObject<any>[],
  eventToSync: string
) => {
  arrayOfCharts.forEach((chart) => {
    chart.current
      ?.getEchartsInstance()
      .getZr()
      .on(eventToSync, (params: any) => {
        const { start, end } = params;
        for (let i = 0; i < arrayOfCharts.length; i++) {
          // console.log(arrayOfCharts[i].current?.getEchartsInstance())
          arrayOfCharts[i].current?.getEchartsInstance().dispatchAction({
            type: "dataZoom",
            // percentage of zoom start position, 0 - 100
            start: start,
            // percentage of zoom finish position, 0 - 100
            end: end,
          });
        }
      });
  });
};

export const turnOnZoomSync = (
  chart: MutableRefObject<any>,
  setZoom: (arg0: { start: any; end: any }) => void
) => {
  chart.current?.getEchartsInstance().on("datazoom", function (params: any) {
    const zoom = params.batch[0];
    setZoom({ start: zoom.start, end: zoom.end });
  });
};
